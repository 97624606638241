// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  list (config, { gpk, gpk_list, major_list, minor_list, fetch_limit, fetch_offset, filter_params_dict, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'major_list': (major_list === null) ? null : JSON.stringify(major_list), 'minor_list': (minor_list === null) ? null : JSON.stringify(minor_list), 'fetch_limit': fetch_limit, 'fetch_offset': fetch_offset, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/wapi/version/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
}
